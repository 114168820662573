import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonTextarea, IonicModule, NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-text-popover',
  templateUrl: './text-popover.component.html',
  styleUrls: ['./text-popover.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, RouterModule],
})
export class TextPopoverComponent {
  @ViewChild('textarea', { static: false }) textarea: IonTextarea;

  placeholder: string;
  title: string;
  text: string;
  isMultiline: boolean;
  shouldSelectAll: boolean;
  shouldSelect: boolean;
  showSaveButton: boolean;
  saveOnEnter: boolean;
  callback: (text: string) => {};

  constructor(
    _navParams: NavParams,
    private popoverCtrl: PopoverController,
  ) {
    setTimeout(() => {
      if (this.shouldSelect) {
        this.textarea.setFocus();
        this.textarea.getInputElement().then((element) => {
          element.setSelectionRange(this.shouldSelectAll ? 0 : this.text?.length, this.text?.length);
        });
      }
    }, 500);
  }

  didEnter() {
    if (this.saveOnEnter) {
      this.text = this.text?.replace(/\n/g, '') ?? '';

      this.save();
    }
  }

  save() {
    this.callback(this.text);
    this.popoverCtrl.dismiss();
  }
}
