<ion-title style="padding: 12px 12px 2px 12px" *ngIf="title">
  <b>{{ title }}</b>
</ion-title>
<ion-textarea
  #textarea
  [rows]="isMultiline ? '3' : '1'"
  [autoGrow]="true"
  [placeholder]="placeholder || 'Enter text...'"
  [(ngModel)]="text"
  style="margin: 0; padding: 4px; font-size: 0.9rem"
  (ionBlur)="showSaveButton = true"
  (ionFocus)="showSaveButton = true"
  (keyup.enter)="didEnter()"
>
</ion-textarea>
<ion-buttons style="padding: 0 10px 10px 10px" [hidden]="!showSaveButton">
  <ion-button (click)="save()" style="margin: 0"> Save </ion-button>
</ion-buttons>
